// Here you can add other styles
@font-face {
  font-family: "Roboto Regular";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto Black";
  src: url("/assets/fonts/Roboto-Black.ttf") format("ttf");
}

body {
  font-family: "Roboto-Regular";
}